module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"","head":true,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"optimizeId":"","experimentId":"","variationId":"","defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"karenshannoncounseling.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jamierosetherapy","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#333","display":"minimal-ui","icon":"src/images/ks.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bc04ddb79ac79fdc0a2dcf60b15b15cf"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
